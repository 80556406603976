<template>
	<div class="layout-footer">
		<span class="font-medium ml-2 mb-2 mr-2">
			<i class="pi pi-check-circle mb-2 mr-2" style="fontSize: 1.5rem" v-if="this.$store.state.estatus"></i>
            <i class="pi pi-exclamation-circle mb-2 mr-2" style="fontSize: 1.5rem" v-else></i> Copyright 2022. Centro integral de fisioterapia y rehabilitación
			<i class="pi pi-mobile mb-2 mr-2" style="fontSize: 1.5rem" v-if="this.$store.state.mobile"></i>
			<i class="pi pi-desktop mb-2 mr-2" style="fontSize: 1.5rem" v-else></i>
		</span>
		<span  class="footer-controls mb-2 mr-2" style="fontSize: 1rem">
                V{{ version }}
        </span>
	</div>
</template>

<script>
	import packageJson from '../package.json';

	export default {
		name: "AppFooter",
		data() {
			return {
				state: null,
				version: packageJson.version
			}
		},
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>