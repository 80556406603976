<template>
	<div class="grid">
		
		<div class="col-12 md:col-12" v-if="paso==0">
			<div class="card p-fluid">
				<div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <Dropdown id="especialidad" v-model="especialidad" :options="especialidades" optionLabel="nombre" :disabled="paso>0"/>
                        <label for="especialidad">Especialidad</label>
                    </span>
                </div>
				<div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText id="correo" v-model="correo" type="email" :disabled="paso>0"/>
                        <label for="correo">Correo</label>
                    </span>
                </div>
				<!--
				<div class="field col-12 md:col-12">
					<div class="field-checkbox mb-0">
						<Checkbox id="nuevo" value="on" v-model="nuevo" :disabled="paso>0"/>
						<label for="checkOption1">Nuevo Cliente</label>
					</div>
                </div>
				-->

				<div class="field col-12 md:col-12" v-if="nuevo.length>0">
                    <div class="formgrid grid">
                        <div class="field col">
							<span class="p-float-label">
								<InputText v-model="nombre" id="nombre" type="text" :disabled="paso>0"/>
								<label for="nombre">Nombre</label>
							</span>
                        </div>
                        <div class="field col">
							<span class="p-float-label">
								<InputText v-model="apellido" id="nuevo" type="text" :disabled="paso>0"/>
								<label for="nuevo">Apellido</label>
							</span>
                        </div>
                    </div>
                </div>

				<div class="field col-12 md:col-12" v-if="nuevo.length>0">
                    <div class="formgrid grid">
                        <div class="field col">
							<span class="p-float-label">
								<InputText v-model="cedula" type="numbre" id="cedula" min="1" :disabled="paso>0"/>
								<label for="cedula">Cédula</label>
							</span>
                        </div>
                        <div class="field col">
							<span class="p-float-label">
								<InputMask v-model="celular" mask="(9999) 999-9999" id="celular" :disabled="paso>0"/>
								<label for="celular">Celular</label>
							</span>
                        </div>
                    </div>
                </div>

			</div>
		</div>


		<div class="col-12 md:col-12"  v-if="paso==1">
			<div class="card p-fluid">
				<h5>Fecha: <Calendar id="icon" v-model="fecha" dateFormat="dd/mm/yy" disabled style="width:200px" autocomplete="off"/></h5>
				<!--
                <Calendar v-model="fecha" dateFormat="dd/mm/yy" :inline="true" :disabledDates="invalidDates"  :minDate="minDateValue" :maxDate="maxDateValue" :disabledDays="dias" :disabled="especialidad==null && paso!=1" autocomplete="off"/>
				-->
				<Calendar v-model="fecha" :inline="true" :disabledDates="fechas"  :minDate="minDateValue" :maxDate="maxDateValue" :disabledDays="dias">
					<template #date="slotProps">
						<strong v-if="slotProps.date.day>0 " class="special-day" disabled>{{slotProps.date.day}}</strong>
						<template v-else>{{slotProps.date.day}}</template>
					</template>
				</Calendar>
			
			</div>

		</div>

		<div class="col-12 md:col-12" v-if="paso==2">
			<div class="card p-fluid">
				<div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <Dropdown id="especialidad" v-model="especialidad" :options="especialidades" optionLabel="nombre" disabled/>
                        <label for="especialidad">Especialidad</label>
                    </span>
                </div>
				<div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText id="correo" v-model="correo" type="email" disabled/>
                        <label for="correo">Correo</label>
                    </span>
                </div>
				<div class="field col-12 md:col-12">
                    <span class="p-float-label">
						<Calendar id="icon" v-model="fecha" :showIcon="true"  dateFormat="dd/mm/yy" disabled autocomplete="off"/>
                        <label for="correo">Fecha</label>
                    </span>
                </div>
			</div>
		</div>

		<div class="col-12 md:col-12" v-if="paso==2">
			<div class="card p-fluid">
				<div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <Dropdown id="especialidad" v-model="especialista" :options="especialistas" optionLabel="nombre" @change="hora=null"/>
                        <label for="especialidad">Especialista</label>
                    </span>
                </div>
			</div>
		</div>
		<div class="col-12 md:col-12"  v-if="paso==2 && especialista">
			<div class="card">
				<h6>Hora</h6>
                <Listbox v-model="hora" :options="especialista.horas" optionLabel="nombre" listStyle="max-height:180px" >
                    <template #option="slotProps">
                        <div class="country-item">
                            <div><i class="pi pi-clock"></i>&nbsp;{{slotProps.option.nombre}}</div>
                        </div>
                    </template>
                </Listbox>
			</div>
		</div>

		<div class="field col-12 md:col-12">
			<Button label="Regresar" @click="Regresar" icon="pi pi-arrow-left" class="p-button-info mr-2 mb-2" v-if="paso>0"></Button> 
			<Button label="Siguiente" @click="Procesar" icon="pi pi-arrow-right" class="p-button-info mr-2 mb-2" iconPos="right" v-if="paso<2"></Button>
			<Button label="Procesar" @click="Agendar" icon="pi pi-check" class="p-button-secondary mr-2 mb-2" iconPos="right" v-else :loading="$store.state.loading" :disabled="$store.state.loading==true || hora==null"></Button>
        </div>

	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('PreCitas','Agendar');

	export default {
		data() {
			return {
				paso: 0,
				especialidad: null,
				especialidades: [],
				especialista: null,
				especialistas: [],
				correo: null,
				nuevo: [],
				nombre: null,
				apellido: null,
				cedula: null,
				celular: null,
                fecha: null,
				fechas: [],
				dias: [],
                hora: null,
				horas: [],
				minDateValue: null,
				maxDateValue: null
			}
		},
		created() {
			this.paso = 0;
			this.Mostrar();
			let invalidDate = new Date();
			let today = new Date();
			invalidDate.setDate(today.getDate() + 9);

			this.invalidDates = [today,invalidDate];
		},
		methods: {
			Mostrar() {
				this.especialidad = null;
				this.especialidades = [];
				Consulta.Ini().then(response => {
					this.$store.commit('Loading');
					//this.$store.state.error = response;
					this.especialidades = response.result.especialidades;
					
				}); 
				this.minDateValue = new Date();
			},
			Regresar(){
				this.paso = this.paso-1;
			},
			Procesar(){
				if(this.paso==0){
					this.fechas = [];
					if (this.especialidad == null) {
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la especialidad', life: 10000});
					} else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
						test(this.correo)) {
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
					} else if (this.nuevo.length>0 && this.nombre == null) {
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca nombre', life: 10000});
					} else if (this.nuevo.length>0 && this.apellido == null){
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca apellido', life: 10000});
					} else if (this.nuevo.length>0 && this.cedula == null){
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca cédula', life: 10000});
					} else if (this.nuevo.length>0 && this.celular == null){
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca número de celular', life: 10000});
					} else {
						let product = {
							correo: this.correo,
							especialidad: this.especialidad
						}
						Consulta.Procesar('Validacion1',{
							product: product
						}).then(response => {
							this.$store.commit('Loading');
							//this.$store.state.error = response;
							if (response.result.correo && this.nuevo.length==0){
								this.paso = 1;
							} else if (this.nuevo.length>0) {
								this.paso = 1;
							} else {
								this.nuevo = [ "on" ];
								this.paso = 0;
							}
							if(response.result.fechas){
								let today = new Date();
								response.result.fechas.forEach(element => {
									let invalidDate = new Date();
									invalidDate.setDate(today.getDate() + element);
									this.fechas.push(invalidDate);
								});
							}					
							this.dias = response.result.dias;
							let today = new Date();
							let minimo = new Date();
							let maximo = new Date();
							minimo.setDate(today.getDate() + response.result.min);
							maximo.setDate(today.getDate() + response.result.max);
							this.minDateValue = minimo;
							this.maxDateValue = maximo;
						});
					}
				}
				if(this.paso==1){
					if (this.fecha == null) {
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la fecha', life: 10000});
					} else {
						let product = {
							correo: this.correo,
							especialidad: this.especialidad,
							fecha: this.fecha,
						}
						Consulta.Procesar('Validacion2',{
							product: product,
						}).then(response => {
							this.$store.commit('Loading');
							//this.$store.state.error = response;
							this.especialistas = response.result;
						});
						this.paso = 2;
					}

				}
			},
			Agendar(){
				if (this.hora == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la hora', life: 10000});
				} else {
					let product = {
						correo: this.correo,
						especialidad: this.especialidad,
						especialista: this.especialista,
						fecha: this.fecha,
						hora: this.hora,
						nombre: this.nombre,
						apellido: this.apellido,
						cedula: this.cedula,
						celular: this.celular,
					}
					Consulta.Procesar('INS',{
						product: product,
					}).then(response => {
						this.$store.commit('Loading');
						//this.$store.state.error = response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Cita Procesada', 
								life: 3000
							});
							this.$store.state.view.cita = false;
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
					});
				}
			},

		},


	}

</script>

