<template>
    <div class="p-fluid" style="width: 100%;">
        <div class="text-center">
            <iframe src="https://maps.google.com/maps?t=m&output=embed&iwloc=near&z=16&q=Casa+NF%2C+Calle+Camaiguana+entre+Tajal%C3%AD+y%2C+2A+C.+Calamar%2C+Lecher%C3%ADa+6016%2C+Anzo%C3%A1tegui@10°11'01.4" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    created() {      
    },
    methods: {

    },
    computed: {


    }
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
</style>