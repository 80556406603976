<template>
	<div :class="containerClass" @click="onWrapperClick">
        <Loading v-model:active="this.$store.state.loading" :is-full-page="fullPage"/>

        <AppTopBar @menu-toggle="onMenuToggle" />
        <div style="
            position: fixed;
            height: 6rem;
            z-index: 997;
            left: 0;
            top: 4.5rem;
            width: 100%;
            padding: 0 2rem;
            background-color: var(--surface-card);
            transition: left $transitionDuration;
            display: flex;
            ">
            <div class="font-bold text-gray-800" style=" font-size: calc(0.9rem + 1.0vw); font-weight: 700;
                position: fixed; height: 5rem; z-index: 997; left: 2.5rem; top: 4.5rem;">
                <span class="font-light block" >
                    <text style="color:#040F54">
                        Centro integral de fisioterapia y rehabilitación
                    </text>
                </span>
                <text style="color:#040F54">Profesionales con calidad humana</text>
            </div>
            <div class="font-normal md:mt-3 text-gray-700" style="font-size: calc(1.0rem + 0.5vw);font-weight: 100;
                position: fixed; height: 5rem; z-index: 997; right: 1.8rem; top: 7.5rem;"
                v-if="this.$store.state.mobile">
                <text style="color:#040F54">Contáctanos (0414) 797 46 24</text>
                
                 
            </div>
            <div class="font-normal md:mt-3 text-gray-700" style="font-size: calc(1.0rem + 0.5vw);font-weight: 100;
                position: fixed; height: 5rem; z-index: 997; right: 1.8rem; top: 4.5rem;" v-else>
                <text style="color:#040F54">Contáctanos (0414) 797 46 24</text>
                <br>
                <text style="color:#040F54">Lecheria - Edo. Anzoátegui <img src="images/ve.jpg" width="22"></text>
            </div>
        </div>
        <div class="layout-sidebar" @click="onSidebarClick">
        <AppMenu :modelext="this.$store.state.menuexterno" :model="this.$store.state.menu" @menuitem-click="onMenuItemClick"  v-if="this.$store.state.authenticated"/>
        </div>

        <div class="layout-main-container">
            <div class="layout-main">
                <div style="color: black;" v-if="this.$store.state.error">{{this.$store.state.error}}</div>
                <Toast/>                
                <router-view :navegar="navegar"/>
            </div>
            <AppFooter />
        </div>

		<!--
        <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />
        -->
        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
//import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    emits: ['change-theme'],
    data() {
        return {
            layoutMode: 'static',
            staticMenuInactive: true,
            overlayMenuActive: false,
            mobileMenuActive: false,
			navegar: null,
            
        }
    },
    created() {
        this.$store.commit('Estatus');
        this.$store.commit('Mobile');
        this.$store.commit('Tasa');
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        onWrapperClick() {
           
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.navegar = this.$store.state.view.landing;

            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }
            if(!this.$store.state.authenticated){this.staticMenuInactive = true;}

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        },


    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.$store.state.MenuActive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.$store.state.MenuActiveMobile,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        //'AppConfig': AppConfig,
        'AppFooter': AppFooter,
        'Loading': Loading,
        
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
