<template>
    <div class="p-fluid">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
            </div>
            <div class="col-12 xl:col-11" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        <!--
                        <img src="layout/images/avatar.png" alt="Image" height="50" class="mb-3">
                        <div class="text-900 text-3xl font-medium mb-3">Welcome, Isabel!</div>
                        
                        <span class="text-600 font-medium">Sign in to continue</span>-->
                    </div>
                
                    <div class="w-full md:w-10 mx-auto">
                        
                        <label class="block text-900 text-xl font-medium mb-2">Correo</label>
                        <InputText v-model="correo" type="email" class="w-full mb-3" placeholder="Correo" style="padding:1rem;" :disabled="validar==true"/>

                        <label class="block text-900 text-xl font-medium mb-2" v-if="validar">Código de Seguridad</label>
                        <InputText v-model="codigo" type="text" class="w-full mb-3" placeholder="Código" style="padding:1rem;" :disabled="valido==true" v-if="validar" @input="ValidarCodigo"/>

                        <label class="block text-900 text-xl font-medium mb-2" v-if="valido">Nombres</label>
                        <InputText v-model="nombre" type="text" class="w-full mb-3" placeholder="Nombres" style="padding:1rem;" v-if="valido"/>

                        <label class="block text-900 text-xl font-medium mb-2" v-if="valido">Apellidos</label>
                        <InputText v-model="apellido" type="text" class="w-full mb-3" placeholder="Nombres" style="padding:1rem;" v-if="valido"/>
                        
                        <label class="block text-900 text-xl font-medium mb-2" v-if="valido">Cédula</label>
                        <InputText v-model="cedula" type="numbre" min="1" v-if="valido"/>

                        <label class="block text-900 text-xl font-medium mb-2" v-if="valido">Celular</label>
                        <InputMask v-model="celular" mask="(9999) 999-9999" placeholder="(9999) 999-9999" v-if="valido"/>

                        <label class="block text-900 font-medium text-xl mb-2" v-if="valido">Contraseña</label>
                        <Password v-model="clave" placeholder="Clave" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem" v-if="valido"></Password>

                        <label class="block text-900 font-medium text-xl mb-2" v-if="valido">Confirmar Contraseña</label>
                        <Password v-model="claveconf" placeholder="Confirmar" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem" v-if="valido"></Password>

                        <Button label="Aceptar" icon="pi pi-check" class="p-button-primary text-xl p-2" :loading="$store.state.loading" :disabled="$store.state.loading==true" @click="Validar" v-if="!validar"/>
                        <Button label="Aceptar" icon="pi pi-check" class="p-button-secondary text-xl p-2" :loading="$store.state.loading" @click="Registra" :disabled="valido==false" v-else/>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import API from "../service/API";
import Crypto from "crypto-js";

export default {
    data() {
        return {
            correo: null,
            clave: null,
            validar: false,
            valido: false,
            claveconf: null,
            codigo: null,
            codigosend: null,
            nombre: null,
            apellido: null,
            cedula: null,
            celular: null
        }
    },
    created() {      

    },
    methods: {
        Validar() {
            if (this.correo == null || this.correo == '') {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca correo', life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
            } else {
                const Consulta = new API('Seguridad','EnviarCodigo');
                Consulta.Procesar(null,{
                    correo: this.correo
                }).then(response => {
                    //this.$store.state.error = response;
                    if(response.codigo){
                        this.codigosend = response.codigo;
                        this.validar = true;
                        this.$toast.add({severity:'success', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                    } else {
                        this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                    } 
                    this.$store.commit('Loading');                  
                });         
            }     
        },
        ValidarCodigo() {
            if (this.codigo == this.codigosend) {
                this.valido = true;
            } else { this.valido = false; }
        },
        Registra() {
            if (this.nombre == null || this.nombre == '') {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Nombre', life: 10000});
            } else if (this.clave == null || this.clave == '') {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Contraseña', life: 10000});
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_.!@#$%^&*])(?=.{8,})/.test(this.clave)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Contraseña', life: 10000});
            } else if (this.clave != this.claveconf) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Confirmación', life: 10000});
            } else {
                const cifrado = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                const Consulta = new API('Seguridad','Registrar');
                Consulta.Procesar(null,{
                    nombre: this.nombre,
                    apellido: this.apellido,
                    cedula: this.cedula,
                    celular: this.celular,
                    correo: this.correo,
                    clave: cifrado
                }).then(response => {
                    //this.$store.state.error = response;	
                    if(response.error){
                        this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                    } else {
                        this.$toast.add({severity:'success', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                        this.$store.commit('Login',response);
                        this.$emit('menuingreso', event);
                        this.$store.state.view.register = false;
                    }  
                    this.$store.commit('Loading');              
                });
            } 
        },

    },
    computed: {


    }
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
</style>