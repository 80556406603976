<template>
    <div class="p-fluid">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
            </div>
            <div class="col-12 xl:col-11" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-3 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                    </div>
                
                    <div class="w-full md:w-10 mx-auto">
                        
                        <label class="block text-900 text-xl font-medium mb-2">Correo</label>
                        <InputText v-model="correo" type="email" class="w-full mb-3" placeholder="Correo" style="padding:1rem;" :disabled="recuperar==true"/>

                        <label class="block text-900 font-medium text-xl mb-2" v-if="!recuperar">Contraseña</label>
                        <InputText v-model="clave" type="password" class="w-full mb-3" placeholder="Clave" style="padding:1rem;"  v-if="!recuperar" @keyup.enter="Ingresar"/>
                        <!--<Password v-model="clave" placeholder="Clave" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem" v-if="!recuperar" @keyup.enter="Ingresar"></Password>-->

                        <label class="block text-900 text-xl font-medium mb-2" v-if="recuperar">Código de Seguridad</label>
                        <InputText v-model="codigo" type="text" class="w-full mb-3" placeholder="Código" style="padding:1rem;" :disabled="validar==true" v-if="recuperar" @input="ValidarCodigo"/>

                        <label class="block text-900 font-medium text-xl mb-2" v-if="validar">Contraseña</label>
                        <Password v-model="clave" placeholder="Clave" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem" v-if="validar"></Password>

                        <label class="block text-900 font-medium text-xl mb-2" v-if="validar">Confirmar Contraseña</label>
                        <Password v-model="claveconf" placeholder="Confirmar" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem" v-if="validar"></Password>

                        <div class="flex align-items-center justify-content-between mb-5">
                            <div class="flex align-items-center">
                                <Checkbox id="rememberme1" v-model="guardar" :binary="true" class="mr-2"></Checkbox>
                                <label for="rememberme1">Recuérdame</label>
                            </div>
                        </div>
                        <div class="flex align-items-center justify-content-between mb-5">
                            <div @click="Recuperar">
                                <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)" >¿Se te olvidó tu contraseña?</a>
                            </div>
                        </div>
                        <Button label="Aceptar" icon="pi pi-check" class="p-button-primary text-xl p-2" :loading="$store.state.loading" @click="Ingresar" v-if="!recuperar" :disabled="$store.state.loading==true"/>
                        <Button label="Aceptar" icon="pi pi-check" class="p-button-secondary text-xl p-2" :loading="$store.state.loading" @click="Reset" v-else/>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import API from "../service/API";
import Crypto from "crypto-js";
//import { truncate } from 'fs';


export default {
    data() {
        return {
            correo: null,
            clave: null,
            guardar: false,
            recuperar: false,
            codigo: null,
            codigosend: null,
            claveconf: null,
            validar: false,
        }
    },
    created() {      
        if (localStorage.getItem('correo') || localStorage.getItem('clave')){
            this.correo = localStorage.getItem('correo');  
            this.clave = localStorage.getItem('clave');  
            this.guardar = true;
        }
    },
    methods: {
        Ingresar() {
            if (this.correo == null || this.correo == '') {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca correo', life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
            } else if (this.clave == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Introduzca contraseña'), life: 10000});
            } else {
                if (this.guardar){
                    localStorage.setItem('correo', this.correo);
                    localStorage.setItem('clave', this.clave);
                } else {
                    localStorage.removeItem('correo');
                    localStorage.removeItem('clave');
                }
                const cifrado = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                this.$store.state.clave = cifrado;
                const Consulta = new API('Seguridad','Login');
                Consulta.Procesar(null,{
                    correo: this.correo,
                    clave:  cifrado
                }).then(response => {
                    //this.$store.state.error = response;
                    if(response.authenticated){
                        this.$toast.add({severity:'success', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                        this.$store.commit('Login',response);
                        this.$emit('menuingreso', event);
                        this.$store.state.view.login = false;
                    } else {
                        this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                    }
                    this.$store.commit('Loading');
                });
            }
        },
        Recuperar() {
            if (this.correo == null || this.correo == '') {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca correo', life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
            } else {
                if (this.guardar){
                    localStorage.setItem('correo', this.correo);
                    localStorage.setItem('clave', this.clave);
                } else {
                    localStorage.removeItem('correo');
                    localStorage.removeItem('clave');
                }
                const Consulta = new API('Seguridad','Reset');
                Consulta.Procesar(null,{
                    correo: this.correo
                }).then(response => {
                    //alert(response.codigo);
                    //this.$store.state.error = response;
                    if(response.correo){
                        this.recuperar = true;
                        this.codigosend = response.codigo;
                        this.$toast.add({severity:'success', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                    } else {
                        this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                    } 
                    this.$store.commit('Loading');                  
                });         
            }     
        },
        ValidarCodigo() {
            if (this.codigo == this.codigosend) {
                this.clave = null;
                this.validar = true;
            } else { this.validar = false; }
        },
        Reset() {
            if (this.clave == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Contraseña', life: 10000});
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_.!@#$%^&*])(?=.{8,})/.test(this.clave)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Contraseña', life: 10000});
            } else if (this.clave != this.claveconf) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Confirmación', life: 10000});
            } else {
                const cifrado = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                const Consulta = new API('Seguridad','ResetClave');
                Consulta.Procesar(null,{
                    correo: this.correo,
                    codigo: this.codigo,
                    clave: cifrado
                }).then(response => {
                    //this.$store.state.error = response;	
                    if(response.error){
                        this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                    } else {
                        this.$toast.add({severity:'success', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                        this.$store.commit('Login',response);
                        this.$emit('menuingreso', event);
                        this.$store.state.view.login = false;
                    }
                    this.$store.commit('Loading');
                });
            } 
        },

    },
    computed: {


    }
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
</style>