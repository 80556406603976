<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" src="logo.png" />
			<!--<span>SAKAI</span>-->

		</router-link>
		<Message severity="warn"  v-if="this.$store.state.view.conexion">Error de Conexión</Message>
		<button class="p-link layout-menu-button layout-topbar-button" @click="$store.commit('MenuActive')" v-if="this.$store.state.authenticated">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>

        <div class="surface-0 align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2" style="top:92%">
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
                <li>
                    <a @click="Navegacion('inicio')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                        <span>Inicio</span>
                    </a>
                </li>
                <li>
                    <a @click="Navegacion('servicios')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                        <span>Servicios y Equipo</span>
                    </a>
                </li>
				<!--
                <li>
                    <a @click="Navegacion('productos')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                        <span>Productos</span>
                    </a>
                </li>
				-->
                <li>
                    <a @click="googlemap" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                        <span>Ubicación</span>
                    </a>
                </li>
            </ul>

            <div class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border lg:py-0 mt-3 lg:mt-0" v-if="!this.$store.state.authenticated">
                <Button label="Registrarse" @click="this.$store.state.view.register=true" class="p-button-rounded border-none ml-1 font-light text-white line-height-2 bg-blue-500"></Button>
                <Button label="Agenda tu cita" @click="this.$store.state.view.cita=true" class="p-button-rounded border-none ml-1 font-light text-white line-height-2 bg-blue-500"></Button>
                <Button label="Ingresar" @click="this.$store.state.view.login=true" class="p-button-rounded border-none ml-1 font-light text-white line-height-2 bg-blue-500"></Button>
            </div>
        </div>


		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button" title="facebook" @click="Mostrar('https://www.facebook.com/fisiomarvzla')">
					<i class="pi pi-facebook" style="font-size: 2rem"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" title="instagram" @click="Mostrar('https://www.instagram.com/fisiomarvzla')">
					<i class="pi pi-instagram" style="font-size: 2rem"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" title="twitter" @click="Mostrar('https://twitter.com/fisiomarvzla')">
					<i class="pi pi-twitter" style="font-size: 2rem"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" title="youtube" @click="Mostrar('https://www.youtube.com/channel/UC2Xh0QLlLt3Xt1o5-vVQMlg')">
					<i class="pi pi-youtube" style="font-size: 2rem"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" title="tiktok" >
					<i class="bi bi-tiktok" style="font-size: 2rem"></i>
					<span>Events</span>
				</button>
			</li>
			<li v-if="this.$store.state.authenticated">
				<button class="p-link layout-topbar-button">
					<i class="pi pi-calendar"></i>
					<span>Events</span>
				</button>
			</li>
			<li v-if="this.$store.state.authenticated">
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li>
			<li v-if="this.$store.state.authenticated">
				<button class="p-link layout-topbar-button" :title="this.$store.state.nombre">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
			</li>
			<li v-if="this.$store.state.authenticated">
				<button class="p-link layout-topbar-button" @click="Logout">
					<i class="pi pi-sign-out"></i>
					<span>Salir</span>
				</button>
			</li>
		</ul>

		<Dialog header="Inicia sesión para continuar" v-model:visible="this.$store.state.view.login" :style="{width: size}" :maximizable="true" :modal="true">
			<Login @menuingreso="onMenu"/>
        </Dialog> 
		
		<Dialog header="Registrarse para continuar" v-model:visible="this.$store.state.view.register" :style="{width: size}" :maximizable="true" :modal="true">
			<Register @menuingreso="onMenu"/>
        </Dialog> 

		<Dialog header="Agendar Cita" v-model:visible="this.$store.state.view.cita" :style="{width: size}" :maximizable="true" :modal="true">
			<AgendarCita/>
        </Dialog> 

		<Dialog header="Ubicación" v-model:visible="ubicacion" style="{width: '50vw'}" :maximizable="true" :modal="true">
			<Ubicacion/>
        </Dialog> 

	</div>
</template>

<script>
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import AgendarCita from './components/AgendarCita.vue';
import Ubicacion from './components/Ubicacion.vue';

export default {
	components: {
        Login,
		Register,
		AgendarCita,
		Ubicacion
    },
	data() {
        return {
            display: false,
			size: '30vw',
			ubicacion: false
        }
    },
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
    },
    methods: {
		googlemap(){
			window.open('https://maps.app.goo.gl/R934rGAspz2UXdMq9', '_blank');
		
		},
        Logout() {
            this.$store.commit('Logout');
			this.onMenuToggle();
        },
		Mostrar(data){
			window.open(data, '_blank');
		},
        Navegacion(id){
            this.$store.state.view.landing = id;
            this.$emit('menu-toggle', event);
        }, 
		onMenu(event) {
			this.$emit('menu-toggle', event);
		},
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>